<script>
	export default {
		data() { 
			return {
				rows: [],
			}
		},
		props:{
			item: Object
		},
		beforeUpdate(){
			if (this.item != undefined){
				this.rows = this.item.spec.split('/')
				this.rows.pop()
			}
		},
		methods:{
			test(text){
				return text.replaceAll('$','<br>')
			}
		}
	}
</script>

<template>
	<div class="MainGrid">
		<div>
			<h1 style="margin: 0;">{{item.name}}</h1>
			<span style="color:#7f5e54;">{{item.type}}</span>
		</div>
		<div>
			<span class="grayed sizeup">{{item.description}}</span><br>
			<div class="padding">
				<a class="shop" target="_blank" :href="item.buyLink">Buy me</a>
			</div>
		</div>
		<div class="row-border"></div>
		<div>
			Specifications
		</div>
		<div><br>
			<div class="container">
				<p class="child" v-for="(line,index) in rows" :key="index"><b>{{line.split('^')[0]}}</b> : <span class="grayed" v-html="test(line.split('^')[1])"></span></p>
			</div>
		</div>
		<div class="row-border"></div>
		<div>
			About
		</div>
		<div><br>
			<span class="grayed" v-html="item.apropos"></span><br>
			
		</div>
	</div>
</template>

<style scoped>

	.sizeup{
		font-size: 30px;
	}

	.MainGrid{
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-auto-rows: minmax(20px, auto);
		gap: 20px;
		margin-top: 40px;
	}

	.container{
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
	}

	.child{
		flex-basis: 300px;
		margin: 10px;
	}
	b{
		font-size: 16px;
	}

	.grayed{
		color: gray;
	}

	.data{
		color: #B59D8D;
	}

	.row-border{
		border-top: 2px solid gray;
		grid-column: 1/ 3;
		height: 0;
	}

	.padding{
		margin-top: 15px;
	}

	.shop{
		padding: 5px;
		text-decoration: none;
		
		border: none;
		background-color: black;
		color: white;
	}

	.shop:hover {
		cursor: pointer;
		color: black;
		background-color: white;
		outline: 1px solid;
	}
</style>
