<script>

</script>

<template>
	<div class="contactDiv">
		<div class="container">
			<div @click="$emit('callback','Home')">
				<!-- <img class="child" src="../../public/assets/Logo_Dewitte_V2.webp" alt="Dewitte Wired Logo"> -->
			</div>
			<a class="child" href = "mailto:nicolas.dewittte@dewitte-wired.fr?subject = Contact Form">Contact</a>
			<button class="child" @click="$emit('callback','Society')"><span class="topButton">The company</span></button>
			<button class="child" @click="$emit('callback','Blog')"><span class="topButton">News</span></button>
		</div>
	</div>

	<div align="center" class="copyrightDiv">
		<p>{{ new Date().getFullYear() }} - Dewitte-Wired.fr | <a @click="$emit('callback','MentionsLegales')">Mentions Légales</a>| <a @click="$emit('callback','PersonalData')">Protection des données personnelles</a> | Made by <a href="https://digitalblend.fr" target="_blank">Digitalblend</a></p>
	</div>

</template>

<style scoped>

	/*THIS IS FOR PHONE*/
	@media (max-width: 500px) {
		img{
			margin: auto;
			width: auto;
			max-height: 60px;
		}
		.contactDiv{
			background-size: 256px;
		}
		.child{
			flex-basis: 100%;
			text-align: center;
		}
	}

	/* THIS IS FOR PC */
	@media (min-width: 500px) {

		img{
			margin: auto;
			width: auto;
			max-height: 70px;
		}
		.contactDiv{
			background-size: 400px;
		}

		.child{
			flex-basis: 250px;
			margin: auto;
		}
	}

	.contactDiv{
		position: relative;
		top: 100%;
		background-color: black;
		background-image: url('../../public/assets/HPgrid1024.webp');
		width: 100%;
		padding-top: 50px;
		padding-bottom: 40px;
	}

	.copyrightDiv a {
		color: black;
	}

	.container{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
	}


	a{
		text-align: center;
		text-decoration: none;
		color: white;
		margin: 5px;
	}

	.topButton{
		color: white;
		font-size: 16px;
	}

	img:hover{
		cursor: pointer;
	}

	button{
		background-color: transparent;
		border: none;
		margin: 5px;
	}

	a:hover{
		text-decoration: underline;
	}

	.topButton:hover{
		text-decoration: underline;
		cursor: pointer;

	}
</style>
