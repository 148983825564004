<script>
import axios from 'axios'
	export default {
		data() {
			return {
				items:{}
			}
		},
		async mounted(){
			axios.get("https://icontrol.digitalblend.fr/api/dewitte/instagram/media")
				.then(response => {
					this.items = response.data
				})

		},
		methods:{
			select(who){
				if(this.selected.length > 1){
					this.$nextTick(() => {
						this.$refs.scrollableDiv[0].scrollTop = 0
					});
				}
				this.selected = this.selected == who ? '' : who
			}
		}
	}
</script>

<template>
	<div class="mainDiv">
		<p class="toptext">Latest News</p><br/>

		<div class="center">
			<div v-for="item in this.items">
				<a href="https://www.instagram.com/dewittewired/?hl=en" target="_blank">
					<img class="item" :src="item.thumbnail_url != undefined ? item.thumbnail_url : item.media_url" :alt="item.id">
				</a>
			</div>
		</div>
		
		<div class="bottom">
			<a class="fa-brands fa-facebook" target="_blank" href="https://www.facebook.com/dewitte.wired/"></a>
			<a class="fa-brands fa-youtube" target="_blank" href="https://www.youtube.com/channel/UCf4VjIlDsxS3W0-gkQp6Bjw"></a>
			<a class="fa-brands fa-instagram" target="_blank" href="https://www.instagram.com/dewittewired/"></a>
		</div>

		<div class="bottom">
			<a class="link" target="_blank" href="https://theguitardivision.com/fr/builders/dewitte-wired.html">
				<img class="guittar" src="../../public/assets/GuitarDivision.svg" alt="Guittar Division Logo">
			</a>
		</div>
	</div>
</template>

<style scoped>

	/* THIS IS FOR mobile */
	@media (max-width: 500px) {
		.mainDiv{
			background-size: 256px;
		}
	}

	@media (max-width: 1300px){
		.center{
			justify-content: start;
		}
	}
	@media(min-width:1300px){
		.center{
			justify-content: center;
		}
	}

	/* THIS IS FOR mobile */
	@media (min-width: 500px) {

		.mainDiv{
			background-size: 400px;
		}
	}
	.mainDiv{
		position: relative;
		background-image: url('../../public/assets/HPgrid1024.webp');
		height: fit-content;
	}

	.link{
		width: 3em;
	}

	.guittar:hover{
		filter: invert(62%) sepia(23%) saturate(319%) hue-rotate(337deg) brightness(99%) contrast(91%);
	}

	.smaller{
		font-size: 25px;
		margin: 0px 10px;
		white-space: break-spaces;
	}

	.toptext{
		padding-top: 20px;
		color:white;
		font-size: 40px;
	}

	.item{
		display: block;
		width: auto;
		height: auto;
		max-height: 220px;
	}

	.mainDiv > *{
		color: white;
		text-align: center;
	}

	.center{
		height: 250px;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		overflow-x: auto;
		gap: 10px;
	}

	.bottom{
		display: flex;
		width: 100%;
		justify-content: center;

		margin-top: 15px;
	}

	a{
		text-decoration: none;
		color: white;
	}

	a:hover{
		color: #B99C8C;
	}

	.bottom > a{
		font-size: 2em;
		margin: 0px 10px;
	}

</style>
