<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				items:{},
				selected:undefined,
			}
		},
		async mounted(){
			//this.switch(this.Models[this.SelectedType][0])
			axios.get("https://icontrol.digitalblend.fr/api/dewitte/blog/all")
				.then(response => {
					this.items = response.data
				})
		},
		methods:{
			select(who){
				console.log(who)
				this.selected=who
				if(!this.selected.description.includes('contentImage')){
					this.selected.description = '<img src="'+who.image+'" class="contentImage" style="float: left;width: auto;max-height: 400px;margin-right:20px;margin-bottom:20px">'+this.selected.description
				}
			}
		}
	}
</script>

<template>
	<div class="blogDiv">
		<div v-if="selected == undefined" @click="select(item)" class="decreased" v-for="item in items">
			<div ref="scrollableDiv" class="inside notScrollable">
				<div class="container" >
					<div class="image" :style="'background-image: url('+item.image+');'" >
					</div>
					<div class="title">
						<h1 >{{item.title}}</h1>
						<p style="font-style: italic;">{{item.subTitle}}</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="bigBackground">
			<button class="close" @click="select(undefined)">Back</button>
			<h1>{{selected.title}}</h1>
			<p>{{selected.subTitle}}</p>
			<div class="content" v-html="selected.description">
				
			</div>
		</div>
	</div>
</template>

<style>

	/* THIS IS FOR PHONE */
	@media (max-width: 500px) {
		.title{
			width: calc(100% - 150px - 1vw);
			text-align: center;
			margin-top: 0;
			height: fit-content;
		}
		.title>h1{
			font-size: 25px;
		}
		.title>p{
			font-size: 15px;
		}
		.image{
			height: 150px;
			width: 150px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.contentImage{
			max-width: calc(100vw - 30px);
		}

		.decreased{		
			flex-basis: 95vw;
			width: 95vw;
			height: 175px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}		
		
		h1{
			font-size: 30px;
			margin: 0;
		}
	}

	/* THIS IS FOR PC */
	@media (min-width: 500px) {
		.title{
			width: calc(100% - 200px - 1vw);
			text-align: center;
			margin-top: 15px;
			height: fit-content;
		}
		.image{
			height: 200px;
			width: 200px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.decreased{
			flex-basis: 500px;
			height: 225px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}

		.decreased:hover{
			background-color: rgb(200,200,200);
		}

		h1{
			font-size: 30px;
		}
	}
	.bigBackground{
		width: 100%;
		min-height: calc(100vh - 120px);
		height: fit-content;
	}
	.bigBackground>h1{
		text-align: center;
		margin-top: 50px;
	}
	.bigBackground>p{
		text-align: center;
		font-style: italic;
	}

	.content{
		margin-top: 50px;
		margin-left: 15px;
		margin-right: 15px;
	}

	.close{
		position: absolute;
		right: 10px;
		top:75px;
	}
	
	.blogDiv{
		overflow: auto;
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		gap:50px;
		justify-content: center;
		width: 100%;
		min-height:calc(100vh - 120px)
	}

	.container{
		display: flex;
		flex-flow: row wrap;
		gap: 1vw;
	}


	.text{
		width: 100%;
		height: 100%;
		text-align: initial;
	}

	.scrollable{
		overflow: auto;
	}

	.inside{
		display: inline-block;
		margin: 10px;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
	}
	
	.decreased:hover{
		cursor: pointer;
	}

	.close{
		border-radius: 5px;
		border: 2px solid #B89D8B;
		background: none;
		padding: 10px;
	}

	.close:hover{
		background-color:#B89D8B;
		cursor: pointer;
	}

</style>
