<script>
	import axios from 'axios'
	
	import ProductDescription from './ProductDescription.vue'
	import FluxRSS from './FluxRss.vue'

	export default{
		data() { 
			return {
				items:{},
				categories: [],
				SelectedType: undefined,
				SelectedModel: {},
				isMobileDetected: false,
				modelViewer: undefined,
			}
		},
		components:{
			ProductDescription,
			FluxRSS,
		},
		async mounted(){
			// this.switch(this.Models[this.SelectedType][0])
			axios.get("https://icontrol.digitalblend.fr/api/dewitte/products/all")
				.then(response => {
					this.items = response.data.items
					this.categories = Object.keys(response.data.items).sort();
					this.SelectedType = this.categories[4]
					this.SelectedModel = this.items[this.SelectedType][4]
					this.SelectedModel.type = this.SelectedModel.type.toString()
				})
		},
		methods:{
			selectNew(name){
				const modelViewer = document.querySelector("model-viewer");
				this.SelectedType = name
				this.SelectedModel = this.items[this.SelectedType][0]
				this.SelectedModel.type = this.SelectedModel.type.toString()
				// this.modelViewer.poster = this.SelectedModel.imageHD

				if (this.isMobileDetected == true) {
					modelViewer.poster = this.SelectedModel.imageHD
				}
			},
			switch(elem){
				const modelViewer = document.querySelector("model-viewer");
				this.SelectedModel = elem
				this.SelectedModel.type = this.SelectedModel.type.toString()
				modelViewer.src = this.SelectedModel.fileGLB
				modelViewer.iosSrc = this.SelectedModel.fileIOS
				modelViewer.poster = this.SelectedModel.imageHD
				//console.log(this.SelectedModel.imageHD)

			},
			isMobile() {
			    if (screen.width <= 760) {
			    	const modelViewer = document.querySelector("model-viewer");
			    	this.modelViewer = modelViewer;
			    	this.isMobileDetected = true
			      	return true
			    } else {
			    	this.isMobileDetected = false
			      	return false
			    }
			},
			startAR() {
				this.modelViewer.activateAR();
			}
		}
	}
</script>

<template>
	<div class="mainPage">
		<div class="middle">
			<div class="inline">
				<!-- PHONE -->
				<div v-if="isMobile()">

					<model-viewer id="viewer" loading="lazy" reveal="manual" class="model-viewer" alt="raf" ar-scale="fixed" :poster="this.SelectedModel.imageHD" ar autoplay ar-modes="webxr scene-viewer quick-look" shadow-intensity="1" camera-controls touch-action="pan-y" :ios-src="this.SelectedModel.fileIOS" :src="this.SelectedModel.fileGLB">

						<div class="slider">
							<div class="slides">
								<button v-for="(value,index) in categories" :key="index" :class="value == this.SelectedType ? 'selected' : ''" class="slide" @click="this.selectNew(value)" :style="'background-image: url('+this.items[value][0].image+');'" >{{value}}
								</button>
							</div>
						</div>
						<div class="middle-right">
							<div class="slider-right">
								<div class="slides-right">
									<button v-for="(element,index) in items[this.SelectedType]" :key="index" :class="element == this.SelectedModel ? 'selected' : ''" class="slide-right" @click="this.switch(element)" :style="'background-image: url('+element.image+');'" >{{element.name}}
									</button>
								</div>
							</div>
						</div>

						<button class="arButton" slot="ar-button" id="ar-button" @click="this.startAR()">
				      		View in your space
				    	</button>

					</model-viewer>
				</div>
				<!-- DESKTOP OR TABLET -->
				<div v-else>
					<model-viewer id="viewer" class="model-viewer" alt="raf" :ios-src="this.SelectedModel.fileIOS" :src="this.SelectedModel.fileGLB" shadow-intensity="1" camera-controls touch-action="pan-y">

						<div class="slider">
							<div class="slides">
								<button v-for="(value,index) in categories" :key="index" :class="value == this.SelectedType ? 'selected' : ''" class="slide" @click="this.selectNew(value)" :style="'background-image: url('+this.items[value][0].image+');'" >{{value}}
								</button>
							</div>
						</div>
						<div class="middle-right">
							<div class="slider-right">
								<div class="slides-right">
									<button v-for="(element,index) in items[this.SelectedType]" :key="index" :class="element == this.SelectedModel ? 'selected' : ''" class="slide-right" @click="this.switch(element)" :style="'background-image: url('+element.image+');'" >{{element.name}}
									</button>
								</div>
							</div>
						</div>
					</model-viewer>
				</div>
			</div>
		</div>
		
		<ProductDescription :item="SelectedModel"/>
	</div>
	<FluxRSS/>
</template>

<style scoped>
	.middle{
		display: flex;
	}
	
	/* THIS IS FOR mobile */
	@media (max-width: 500px) {
		html, body {
			max-width: 100%;
			overflow-x: hidden;
		}
		.model-viewer{
			width: 100vw;
			height: calc(80vh - 100px);
			background-color: #eee;
			left: -10px;
		}

		.middle-right{
			display: inline-block;
			position: absolute;
			right: 20px;
			height: calc(80vh - 100px);
		}
		.inline{
			width: 100%;
			left: -10px;
		}

		.mainPage{
			margin: 0px 10px;
		}

		.slide{
			width: 75px;
			height: 75px;
		}
		.slider{
			margin: 0px 10px;
		}

		.slider-right{
			height: calc(50vh - 100px);
		}

		.slide-right{
			width: 40px;
			height: 40px;
		}

		.slider-right{
			width: 50px;
		}

		.banner{
			background-size: 256px;
		}
	}
	
	/* THIS IS FOR PC */
	@media (min-width: 500px) {
		.model-viewer{
			width: 100%;
			height: calc(80vh - 100px);
			background-color: #eee;
		}
		.middle{
			margin-left:-30px;
		}

		.middle,.inline{
			overflow: hidden;
			width: calc(100vw - 15px);
		}

		.middle-right{
			display: inline-block;
			position: absolute;
			right: 0px;
			height: calc(80vh - 100px);
		}
		.inline{
			width: 100%;
			left: -10px;
		}

		.slide{
			width: 100px;
			height: 100px;
		}


		.slides{
			margin: 0px 20px;
			justify-content: center;
		}

		.slider-right{
			height: calc(80vh - 100px);
		}

		.mainPage{
			margin: 0px 30px;
		}

		.slide-right{
			width: 100px;
			height: 100px;
		}

		.slider-right{
			width: 120px;
		}

		.banner{
			background-size: 400px;
		}
	}

	.title{
		text-align: center;
		font-size: 50px;
	}

	.subTitle{
		text-align: center;
		font-size: 30px;
	}

	.shop{
		padding: 5px;
		position: absolute;
		right: 25px;
		margin-top: 18px;
		text-decoration: none;
		outline: 1px solid;
		border: none;
		background-color: transparent;
		color: white;
	}

	.shop:hover {
		cursor: pointer;
		color: black;
		background-color: white;
		outline: none;
	}

	.logo{
		width: 256px;
		margin-top: 10px;
	}

	.slider {
		width: 100%;
		overflow: hidden;
		position: absolute;
		bottom: 16px;
		margin-top: 20px;
	}

	.slides {
		display: flex;
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}

	.slide {
		flex-shrink: 0;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #fff;
		margin-right: 10px;
		border-radius: 10px;
		border: none;
		display: flex;
		color: rgba(0, 0, 0, 0.01);

	}

	.slider-right {
		text-align: center;
		overflow: hidden;
		bottom: 16px;

	}

	.slides-right {
		width: inherit;
		height: inherit;
		display: inline-block;
		overflow-y: auto;
		overflow-x: hidden;
		scroll-snap-type: y mandatory;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}

	.slide-right {
		scroll-snap-align: start;
		background-size: contain;
		background-color: #FEFEFE;
		margin-top: 10px;
		border-radius: 10px;
		border: none;
		display: inline-block;
		color: rgba(0, 0, 0, 0.01);
	}

	.selected {
		border: 2px solid #B99C8C;
	}

	#error {
		background-color: #ffffffdd;
		border-radius: 16px;
		padding: 16px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: opacity 0.3s;
	}

	#error.hide {
		opacity: 0;
		visibility: hidden;
		transition: visibility 2s, opacity 1s 1s;
	}

	.arButton{
		margin: 5px;
		padding: 5px;
		text-decoration: none;
		outline: 1px solid black;
		border: none;
		background-color: white;
	}

	.arButton:hover{
		cursor: pointer;
		color: white;
		background-color: black;
		outline: none;
	}
</style>
