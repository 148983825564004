<template>
	<div class="blogDiv">
		<div class="container">
			<div class="title"><h1>Mentions Légales</h1></div>
			<div class="subTitle"><h3>Retrouvez les informations légales</h3></div>

			<hr width="50%" />

			<div class="content">
				<p style="font-weight: bold;">Editeur du site internet</p>
				<p>
					Hexa-Tone<br/>
					Site Internet : https://www.dewitte-wired.fr– Email : contact@dewitte-wired.fr<br/>
					Siège social : 10Bis Route d'Ax 31120 Portet-sur-garonne
				</p>

				<p style="font-weight: bold;">Responsable de la publication du site</p>
				<p>
					Nicolas Dewitte
				</p>

				<p style="font-weight: bold;">Design produits</p>
				<p>
					Les designs de tous les produits présents sur ce site internet sont la propriété exclusive de la société Hexa-Tone. Toute utilisation ou reproduction est formellement interdite sans autorisation.
				</p>

				<p style="font-weight: bold;">Crédit Photos – Logo</p>
				<p>
					Toutes les photos et les logos présents sur ce site internet sont la propriété exclusive de la société Hexa-Tone. Toute utilisation ou reproduction est formellement interdite sans autorisation.
				</p>

				<p style="font-weight: bold;">Conception du site internet</p>
				<p>
					Une réalisation Digitalblend – www.digitalblend.fr<br/>
					WebDesign : Digitalblend<br/>
					Développement – Intégration : Digitalblend
				</p>

				<p style="font-weight: bold;">Hébergement du site internet</p>
				<p>
					Le site internet est hébergé par la société OVH
				</p>

				<p style="font-weight: bold;">Loi informatique et libertés</p>
				<p>
					Mentions légales réglementées par la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés et la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.
				</p>

				<p style="font-weight: bold;">Données personnelles</p>
				<p>
					Vos données personnelles (nom, prénom, adresse, code postal, ville, e-mail, …) sont collectées dans le strict respect de la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.<br/>Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés modifiée par la nouvelle loi Informatique et Libertés du 6 août 2004, vous disposez d’un droit d’accès, de modifications, de rectifications et de suppression des données qui vous concernent. Pour cela il vous suffit de nous contacter par courrier, par e-mail ou par téléphone.
				</p>

				<p style="font-weight: bold;">Cookies</p>
				<p>
					L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Un cookie est un élément qui ne permet pas d’identifier l’utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet. Le paramétrage de votre logiciel de navigation permet d’informer de la présence de cookies et éventuellement de la refuser selon la procédure décrite à l’adresse suivante : www.cnil.fr.
				</p>
			</div>

		</div>
	</div>

</template>

<style>

	/* THIS IS FOR PHONE */
	@media (max-width: 500px) {
		.title{
			width: calc(100%);
			text-align: center;
			margin-top: 10px;
			height: fit-content;
		}
		.subTitle {
			width: calc(100%);
			text-align: center;
			margin-top: 0px;
			height: fit-content;
			margin-top: -20px;
		}
		.title>h1{
			font-size: 25px;
		}
		.title>p{
			font-size: 15px;
		}
		.image{
			height: 150px;
			width: 150px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.contentImage{
			max-width: calc(100vw - 30px);
		}

		.decreased{		
			flex-basis: 95vw;
			width: 95vw;
			height: 175px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}		
		
		h1{
			font-size: 30px;
			margin: 0;
		}

	}

	/* THIS IS FOR PC */
	@media (min-width: 500px) {
		.title{
			width: calc(100%);
			text-align: center;
			margin-top: 15px;
			height: fit-content;
		}
		.subTitle {
			width: calc(100%);
			text-align: center;
			margin-top: 0px;
			height: fit-content;
			margin-top: -40px;
		}
		.image{
			height: 200px;
			width: 200px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.decreased{
			flex-basis: 500px;
			height: 225px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}

		.decreased:hover{
			background-color: rgb(200,200,200);
		}

		h1{
			font-size: 30px;
		}
	}
	.bigBackground{
		width: 100%;
		min-height: calc(100vh - 120px);
		height: fit-content;
	}
	.bigBackground>h1{
		text-align: center;
		margin-top: 50px;
	}
	.bigBackground>p{
		text-align: center;
		font-style: italic;
	}

	.content{
		background-color: transparent;
		display: inline-block;
	    width: 50%;
	    position: relative;
    	left: 25%;
	}

	.close{
		position: absolute;
		right: 10px;
		top:75px;
	}
	
	.blogDiv{
		overflow: auto;
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		gap:50px;
		justify-content: center;
		width: 100%;
		min-height:calc(100vh - 120px)
	}

	.container{
		display: flex;
		flex-flow: row wrap;
		gap: 1vw;
	}

	.text{
		width: 100%;
		height: 100%;
		text-align: center;
	}

	.scrollable{
		overflow: auto;
	}

	.inside{
		display: inline-block;
		margin: 10px;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
	}
	
	.decreased:hover{
		cursor: pointer;
	}

	.close{
		border-radius: 5px;
		border: 2px solid #B89D8B;
		background: none;
		padding: 10px;
	}

	.close:hover{
		background-color:#B89D8B;
		cursor: pointer;
	}

</style>