<template>
	<div class="bg">
		<div class="banner">
			<div class="topBannerButton" @click="ChangeVue('Home')">
				<img src="../public/assets/Logo_Dewitte_V2.webp" class="logo" alt="Dewitte Wired Logo">
			</div>
			<button class="topBannerButton" @click="ChangeVue('Blog')"> News</button>
		</div>
		<MainPage v-if="this.choice == 'Home'"/>
		<Society v-if="this.choice == 'Society'"/>
		<Blog v-if="this.choice == 'Blog'"/>
		<MentionsLegales v-if="this.choice == 'MentionsLegales'"/>
		<PersonalData v-if="this.choice  == 'PersonalData'"/>
		<Dewitte class="contact" @callback="ChangeVue"/>
	</div>
	
</template>

<script>
import MainPage from './components/MainPage.vue'
import Dewitte from './components/DewitteContact.vue'
import Society from './components/NotreSociete.vue'
import Blog from './components/Blog.vue'
import MentionsLegales from './components/MentionsLegales.vue'
import PersonalData from './components/PersonalData.vue'

export default {
	name: 'DewitteWired',
	data(){
		return {
			choice:"Home"
		}
	},
	components: {
		MainPage,
		Dewitte,
		Society,
		Blog,
		MentionsLegales,
		PersonalData,
	},
	methods:{
		ChangeVue(newPage){
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			this.choice = newPage
		},
	}
}

</script>

<style>

	.bg{
		background-image: url("./bg.jpg");
	}

	@font-face{

		font-family: DIN Pro;
		src: url(../public/assets/FontsFree-Net-DINPro-1.ttf);
		font-display: swap;
	}

	*{
		font-family: DIN Pro;
	}

	.contact{
		position: absolute;
		bottom: 0;
	}

	@media (max-width: 500px) {
		.banner{
			background-size: 256px;
		}
		.logo{
			margin-top: 10px;
			width:255px;
			height:35px;
			padding-left:10px;
		}
	}
	
	/* THIS IS FOR PC */
	@media (min-width: 500px) {
		.banner{
			background-size: 400px;
		}
		.logo{
			margin-top: 3px;
			width:400;
			height:55px;
			padding-left:10px;
		}
	}

	.banner{
		width: 100%;
		height: 64px;
		background-color: black;
		background-image: url('../public/assets/HPgrid1024.webp');
		color: blue;
		display: flex;
		justify-content: space-between;
	}

	.topBannerButton{
		background-color: transparent;
		font-size: 16px;
		border: none;
		color: white;
		margin-right: 50px;
	}

	.topBannerButton:hover{
		cursor: pointer;
		color: #B99C8C;
	}

	

</style>
