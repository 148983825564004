<template>
	<div class="blogDiv">
		<div class="container">
			<div class="title"><h1>Protection des données personnelles</h1></div>

			<hr width="50%" />

			<div class="content">
				<p style="font-weight: bold;">Identité du responsable du traitement</p>
				<p>
					Hexa-Tone, immatriculée au Registre du Commerce et des Sociétés de Toulouse sous le numéro 832919963RM31 et dont le siège social se situe au 10Bis Route d'Ax 31120 Portet-sur-garonne représentée par Nicolas Dewitte
				</p>

				<p style="font-weight: bold;">Définitions :</p>

				<p style="font-weight: bold;">Utilisateur :</p>
				<p>
					toute personne physique, possédant un compte sur le site ou non, utilisant ce site internet ou l’un des services proposés sur celui-ci.
				</p>

				<p style="font-weight: bold;">Site :</p>
				<p>
					il s’agit du site www.dewitte-wired.fr .
				</p>

				<p style="font-weight: bold;">Collecte et finalités du traitement des données</p>
				<p>
					Ce site internet collecte des données à caractère personnel sur tout utilisateur naviguant, consultant et commandant sur notre site internet, notamment par l’utilisation de cookies conformément à la législation en vigueur.<br/><br/>

					Cette collecte de données s’effectue lorsque l’utilisateur :<br/><br/>

					    - remplit un formulaire de saisie d’informations<br/>
					    - contacte le Service Client par tout moyen de communication mis à sa disposition tel que formulaire de contact, courrier électronique, la messagerie instantanée (Tchat), etc.<br/>
					    - navigue sur le Site<br/>
					    - s’inscrit aux newsletters proposées par notre entreprise<br/><br/>

					Les données collectées sont nécessaires aux traitements suivants :<br/><br/>

					    - Réalisation de statistiques de la mesure d’audience et d’études marketing<br/>
					    - Gestion et suivi des relations commerciales entre Hexa-Tone et le client<br/>
					    - Envoi de prospection commerciale (voir paragraphe « Newsletters »)<br/>
					    - Affichage de publicités ciblées notamment sur les réseaux sociaux

				</p>

				<p style="font-weight: bold;">Formulaire de contact et questionnaires</p>
				<p>
					L’Utilisateur devra renseigner tous les champs du formulaire de saisie d’informations sur www.dewitte-wired.fr à l’exception des champs non affichés comme obligatoires, et devra donner explicitement son consentement sur l’utilisation des données qui en résulteront.<br/><br/>

					A défaut, le formulaire de contact ne sera pas envoyé. Hexa-Tone ne donnera pas suite aux demandes de l’Utilisateur.
				</p>

				<p style="font-weight: bold;">Destinataires des données</p>
				<p>
					Les données collectées sont destinées à l’usage de Hexa-Tone<br/><br/>

					Les données peuvent également être traitées par des sous-traitants auxquels Hexa-Tone fait appel dans le cadre de la gestion des contenus du site.
				</p>

				<p style="font-weight: bold;">Droit d’accès, de rectification et de suppression</p>
				<p>
					Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée, l’utilisateur dispose d’un droit d’accès, de rectification et de suppression des données le concernant. L’utilisateur peut expressément s’opposer au traitement de ses données personnelles.<br/><br/>

					Pour cela, il lui suffit d’écrire un courrier à Hexa-Tone ou remplir le formulaire en ligne de contact du site accessible à l’adresse https://www.dewitte-wired.fr/formulaire-de-protection-des-donnees-personnelles/ en choisissant comme sujet « Droit d’accès, de rectification ou d’opposition au traitement de mes données».<br/><br/>

					L’Utilisateur indique ses nom, prénom et adresse postale. Sa demande doit être signée et accompagnée d’une pièce d’identité en cours de validité portant sa signature. L’Utilisateur peut préciser l’adresse à laquelle doit lui parvenir la réponse.<br/><br/>

					Hexa-Tone dispose d’un délai de 2 mois à compter de la réception de la demande de l’Utilisateur pour y répondre. A l’issue de ce délai, le Client pourra saisir la CNIL en cas de réponse négative ou absence de réponse de la part de Hexa-Tone.
				</p>

				<p style="font-weight: bold;">Transfert de données personnelles en dehors de l’Union européenne</p>
				<p>
					Hexa-Tone informe l’Utilisateur que ses données personnelles peuvent être transmises à des prestataires situés aux Etats-Unis dans le cadre de l’optimisation de ses campagnes publicitaires. Ces prestataires agissent dans le respect des dispositions européennes relatives à la protection de données personnelles de la convention « Privacy Shield ».
				</p>

				<p style="font-weight: bold;">Durée de conservation des données</p>
				<p>
					Hexa-Tone conserve les données pendant une durée de cinq années à compter de la fin des relations avec le client ou le prospect. Le délai commence à partir soit du dernier appel au service client, ou de l’envoi d’un courriel au service client, ou d’un clic sur un lien hypertexte d’un courriel adressé par Hexa-Tone. A l’issue de ce délai les données du client ou prospect seront anonymisées.<br/><br/>
					Néanmoins, les informations permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale peuvent être archivées conformément aux dispositions légales en vigueur.
				</p>

				<p style="font-weight: bold;">Sort des données personnelles après le décès</p>
				<p>
					Hexa-Tone informe l’Utilisateur qu’il peut définir, de son vivant, des directives relatives à la conservation, à l’effacement et à la communication de ses données personnelles après son décès. Ces directives peuvent être générales ou particulières. L’Utilisateur peut modifier ou révoquer ses directives à tout moment. Pour ce faire, l’Utilisateur peut contacte Hexa-Tone via le formulaire en ligne à l’adresse  https://www.dewitte-wired.fr/formulaire-de-protection-des-donnees-personnelles/ en choisissant comme sujet « Le sort de mes données après mon décès ».
				</p>

				<p style="font-weight: bold;">Newsletters</p>
				<p>
					Lors de la création de son compte et ultérieurement dans la gestion de ses préférences via la rubrique « Mon compte », l’Utilisateur peut s’inscrire à la newsletter de Hexa-Tone et ainsi choisir d’être informé à intervalle régulier des offres proposées sur le Site. Il a à tout moment la possibilité de moduler les informations qu’il souhaite recevoir ou de se désabonner en cliquant sur le lien prévu à cet effet situé en bas de chacune des newsletters. Ce lien est également accessible sur le Site dans la rubrique « Mon compte ».
				</p>

				<p style="font-weight: bold;">Courrier postal et numéro de téléphone</p>
				<p>
					L’adresse postale et le numéro de téléphone du client sont nécessaires au traitement et à la gestion des commandes ainsi qu’aux relations commerciales entr Hexa-Tone et le client.<br/><br/>

					Hexa-Tone  utilise ces données personnelles à des fins de prospection commerciale. À tout moment, le client a la possibilité de s’y opposer de manière simple et gratuite sur le Site via le formulaire de contact du site.<br/>
					L’utilisateur qui ne souhaite pas faire l’objet de prospection commerciale par voie téléphonique est informé qu’il dispose du droit de s’opposer au démarchage téléphonique en entrant ses numéros de téléphone fixes et/ou portables sur la liste d’opposition gratuite accessible via le site http://www.bloctel.gouv.fr/. L’utilisateur est informé que la prise en compte effective de sa demande se fait dans un délai de 30 jours à compter de la confirmation de son inscription. Cette inscription est valable pour une durée de 3 ans.
				</p>

				<p style="font-weight: bold;">SMS / MMS</p>
				<p>
					Hexa-Tone peut utiliser le numéro de téléphone portable du client pour la gestion des commandes et peut également l’utiliser à des fins de prospection commerciale. À tout moment le client a la possibilité de s’y opposer de manière simple et gratuite sur le Site via le formulaire de contact du site.
				</p>

				<p style="font-weight: bold;">Cookies</p>

				<p style="font-weight: bold;">Définition du cookie</p>
				<p>
					Un cookie est un petit fichier texte qui est placé ou stocké sur votre terminal (ordinateur, tablette ou appareil mobile) lorsque vous visitez un site internet. Le cookie permet à un site de vous reconnaître, de vous aider à naviguer de page en page sur un site internet, fournit des connexions sécurisées et se souvient de vos préférences lors de vos prochaines visites.<br/><br/>
					Hexa-Tone a recours à l’utilisation de cookies afin de reconnaître l’Utilisateur (client ou non) lors de sa connexion sur le Site.
				</p>

				<p style="font-weight: bold;">Suppression des cookies</p>
				<p>
					L’Utilisateur peut à tout moment désactiver les cookies enregistrés sur son terminal. Pour cela, il lui suffit de sélectionner les paramètres appropriés dans son navigateur. Cependant, cette désactivation aura pour conséquence d’empêcher l’accès à certaines fonctionnalités du Site permettant de personnaliser les services proposés par Hexa-Tone.
				</p>

				<p style="font-weight: bold;">Les différents types de cookies utilisés</p>
				<p>
					
				    - Les cookies strictement nécessaires : ces cookies vous permettent de naviguer sur le Site et sont indispensables au bon fonctionnement du Site. Leur désactivation entraînera des difficultés dans l’utilisation du Site et aura pour conséquence d’empêcher l’accès à certaines fonctionnalité<br/><br/>
				    - Les cookies fonctionnels : ces cookies permettent de mémoriser vos préférences, vos choix afin de personnaliser votre expérience sur le Site.<br/><br/>
				    - Les cookies de performance et statistiques : ces cookies collectent des informations sur la façon dont les Utilisateurs utilisent le Site (le nombre de visites, le nombre de pages consultées, l’activité des visiteurs) via les outils d’analyse Google Analytics et Ces cookies nous permettent également d’identifier et de résoudre les problèmes de fonctionnement du Site et de l’améliorer.<br/><br/>
				    - Les cookies publicitaires : ces cookies enregistrent des informations pour vous adresser des publicités personnalisées et adaptées en fonction de vos centres d’intérêts. Ils nous aident également à comptabiliser l’activité de nos espaces publicitaires, à mesurer l’audience, etc…). Par exemple, les cookies liés aux opérations relatives à la publicité ciblée, traceurs de réseaux sociaux générés par les boutons de partage de réseaux sociaux tels que Facebook, Twitter, Pinterest, Youtube, Google +, Instagram (Plugins sociaux).<br/><br/>
				    - Les boutons réseaux sociaux : Hexa-Tone met à la disposition des internautes des boutons de partage tels que Facebook, Twitter (voir paragraphe « Plugins réseaux sociaux » ci-dessous).

				</p>

				<p style="font-weight: bold;">La gestion des cookies</p>
				<p>
					Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies.
				</p>

				<p style="font-weight: bold;">Désactivation des cookies via les principaux navigateurs :</p>

				<p style="font-weight: bold;">Internet explorer</p>
				<p>
					1) Dans le menu « Outils », sélectionnez « Options Internet »<br/>
					2) Cliquez ensuite sur l’onglet « Confidentialité »<br/>
					3) Cliquez sur le bouton « Avancé » pour faire apparaitre la fenêtre « Paramètres de confidentialité avancés ».<br/>
					4) Cochez ensuite la case « Ignorer la gestion automatique des cookies », puis sélectionnez « Refuser » et sauvegardez vos préférences en cliquant sur « OK ».
				</p>

				<p style="font-weight: bold;">Mozilla Firefox</p>
				<p>
					1) Dans le menu en haut de la page cliquez sur « Outils », puis « Options »<br/>
					2) Sélectionnez l’onglet « Vie privée »<br/>
					3) Paramétrer le menu « Règles de conservation », cliquez sur « utiliser les paramètres personnalisés pour l’historique »<br/>
					4) Décochez la case « accepter les cookies tiers » et sauvegardez vos préférences en cliquant sur « OK ».
				</p>

				<p style="font-weight: bold;">Google Chrome</p>
				<p>
					1) Dans le menu, cliquez sur « Paramètres » puis sur « Afficher les paramètres avancés »<br/>
					2) Sélectionnez l’onglet « Confidentialité » puis le menu « Paramètres de contenus ».<br/>
					3) Cochez la case « Bloquer les cookies et données de sites tiers » et sauvegardez vos préférences en cliquant sur « OK ».
				</p>

				<p style="font-weight: bold;">Safari</p>
				<p>
					1) Dans le menu en haut de la page, cliquez sur « Safari », puis « Préférences »<br/>
					2) Cliquez sur l’onglet « Confidentialité »<br/>
					3) Décochez la case « Accepter les cookies » et sauvegardez vos préférences.
				</p>

				<p style="font-weight: bold;">Module de gestion des cookies</p>
				<p>
					Hexa-Tone met à la disposition des Utilisateurs du Site un module leur permettant de s’opposer aux cookies déposés sur le Site. L’Utilisateur peut cliquer sur le bouton « Personnaliser » présent dans le bandeau d’information mentionnant les finalités des cookies déposés.
				</p>

				<p style="font-weight: bold;">Mesures de sécurité prises pour assurer la protection des données personnelles</p>
				<p>
					Hexa-Tone met en œuvre tout moyen technique conformément aux règles pour maintenir l’intégrité, la sécurité, la confidentialité des données et notamment empêcher qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient accès.
				</p>

				<p style="font-weight: bold;">Recrutement et candidatures</p>
				<p>
					Les informations nominatives recueillies sont traitées informatiquement conformément à la loi du 6 janvier 1978 dans le but de procéder à la gestion et au suivi des candidatures via le formulaire de contact de notre Site. Le responsable du traitement est Hexa-Tone(voir « Identité du responsable du traitement »).<br/><br/>

					Les données recueillies sont destinées à l’usage de Hexa-Tone et sont conservées pendant une durée de 2 ans à compter du dernier contact avec le candidat.<br/><br/>

					Conformément à la loi du 6 janvier 1978 modifiée, les candidats disposent d’un droit d’accès, de modification, de rectification et de suppression des données personnelles les concernant. Le candidat peut s’opposer à la divulgation de ses coordonnées. Il lui suffit d’écrire par courrier à Hexa-Tone<br/><br/>

					Il indiquera ses noms, prénoms et adresse postale. Sa demande doit être signée et précisant l’adresse à laquelle doit lui parvenir la réponse. Une réponse lui sera alors adressée dans un délai de 2 mois suivant la réception de la demande, cachet de la poste faisant foi.
				</p>

			</div>
		</div>
	</div>

</template>

<style>

	/* THIS IS FOR PHONE */
	@media (max-width: 500px) {
		.title{
			width: calc(100%);
			text-align: center;
			margin-top: 10px;
			height: fit-content;
		}
		.subTitle {
			width: calc(100%);
			text-align: center;
			margin-top: 0px;
			height: fit-content;
			margin-top: -20px;
		}
		.title>h1{
			font-size: 25px;
		}
		.title>p{
			font-size: 15px;
		}
		.image{
			height: 150px;
			width: 150px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.contentImage{
			max-width: calc(100vw - 30px);
		}

		.decreased{		
			flex-basis: 95vw;
			width: 95vw;
			height: 175px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}		
		
		h1{
			font-size: 30px;
			margin: 0;
		}

	}

	/* THIS IS FOR PC */
	@media (min-width: 500px) {
		.title{
			width: calc(100%);
			text-align: center;
			margin-top: 15px;
			height: fit-content;
		}
		.subTitle {
			width: calc(100%);
			text-align: center;
			margin-top: 0px;
			height: fit-content;
			margin-top: -40px;
		}
		.image{
			height: 200px;
			width: 200px;
			background-size: cover;
			background-position: center center;
			border-radius: 15px;
		}

		.decreased{
			flex-basis: 500px;
			height: 225px;
			margin-top: 20px;
			border-radius: 15px;
			border: 2px solid #B89D8B;
			box-shadow: 5px 5px 5px #B89D8B;
			color: black;
			background-size: cover;
			transition: 0.3s;
		}

		.decreased:hover{
			background-color: rgb(200,200,200);
		}

		h1{
			font-size: 30px;
		}
	}
	.bigBackground{
		width: 100%;
		min-height: calc(100vh - 120px);
		height: fit-content;
	}
	.bigBackground>h1{
		text-align: center;
		margin-top: 50px;
	}
	.bigBackground>p{
		text-align: center;
		font-style: italic;
	}

	.content{
		background-color: transparent;
		display: inline-block;
	    width: 50%;
	    position: relative;
    	left: 25%;
	}

	.close{
		position: absolute;
		right: 10px;
		top:75px;
	}
	
	.blogDiv{
		overflow: auto;
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		gap:50px;
		justify-content: center;
		width: 100%;
		min-height:calc(100vh - 120px)
	}

	.container{
		display: flex;
		flex-flow: row wrap;
		gap: 1vw;
	}

	.text{
		width: 100%;
		height: 100%;
		text-align: center;
	}

	.scrollable{
		overflow: auto;
	}

	.inside{
		display: inline-block;
		margin: 10px;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
	}
	
	.decreased:hover{
		cursor: pointer;
	}

	.close{
		border-radius: 5px;
		border: 2px solid #B89D8B;
		background: none;
		padding: 10px;
	}

	.close:hover{
		background-color:#B89D8B;
		cursor: pointer;
	}

</style>